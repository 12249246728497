<template>
	<div class="sections">
		<section id="now" class="section now col">
			<h2 class="heading">Now</h2>
			<p class="content">Since 2011, together with my good friend <a class="link" href="https://twitter.com/pawelmag" target="_blank" rel="noopener">Paweł</a> I run a small studio called <a class="link" href="https://mtw.team?ref=lukeaf" rel="noopener">MTW</a>. We’re helping people be more productive by building macOS applications: <a class="link" href="https://cleanshot.com?ref=lukeaf" rel="noopener">CleanShot&nbsp;X</a> and <a class="link" href="https://getpixelsnap.com?ref=lukeaf" rel="noopener">PixelSnap&nbsp;2</a> </p>
		</section>
		<section id="past" class="section past col">
			<h2 class="heading">Past</h2>
			<p class="content">I've been making stuff on the Internet since I was 11 years old. Here are some of the things I worked on:</p>
			<ul class="list">
				<li class="item"><a class="link" href="https://revision.lukasz.cc" target="_blank" rel="noopener">reVision</a> - Photo comparison tool</li>
				<li class="item"><a class="link" href="https://epicblackfriday.co" target="_blank" rel="noopener">Epic Black Friday</a> - Curated list of deals for designers &amp; developers</li>
				<li class="item"><a class="link" href="https://ilendapp.com" target="_blank" rel="noopener">iLend</a> - Mobile app for tracking borrowed things</li>
				<li class="item"><a class="link" href="https://pluginmate.com" target="_blank" rel="noopener">PluginMate</a> - Time-saving Photoshop plugins for designers</li>
				<li class="item"><a class="link" href="https://symu.co/addons/" target="_blank" rel="noopener">Symu PS &amp; Sketch</a> - Photoshop plugins made for Symu</li>
				<li class="item"><a class="link" href="https://dribbble.com/shots/1932107-Dribbblr-Widget-for-iOS" target="_blank" rel="noopener">Dribbblr</a> - Dribbble widget for iOS</li>
				<li class="item"><a class="link" href="https://own.li" target="_blank" rel="noopener">own.li</a> - Simple URL Shortener</li>
			</ul>
			<p class="content">And much more. Most of the products aren't live now, so I'll be adding a more descriptive section soon.</p>
		</section>
	</div>
</template>

<script>
export default {
	name: 'Section'
}
</script>

<style lang="scss" scoped>
.section {

	margin-top: 80px;

}

.heading {

	margin-bottom: 30px;

	font-weight: $medium;
	font-size: 48px;
	color: $color-grayscale-100;

	&::before {

		content: "# ";
		margin-left: -45px;

		@media (max-width: 700px) {
			margin-left: 0;
		}
	}

	@media (prefers-color-scheme: dark) {
		color: #ffffff;
	}

}

.content {

	max-width: 600px;

	font-size: 22px;
	color: $color-grayscale-80;
	line-height: 1.75;

	@media (prefers-color-scheme: dark) {
		color: #dfdfdf;
	}

}

.link {

	display: inline-block;
	padding: 0 6px;
	position: relative;

	font-size: 22px;
	font-weight: $medium;
	line-height: 1.4;

	background-image: linear-gradient(90deg, #FF9900, #FF565B, #FF9900);
	background-size: 200% 100%;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	transition: background-position 0.5s ease;

	&:hover {
		background-position: 100% 0;
	}

	&::before {

		width: 100%;
		height: 100%;

		content: "";

		position: absolute;
		left: 0;
		top: 0;

		background-color: rgba(#FF9801, 0.15);
		border-radius: 6px;
		transition: background-color 0.15s ease;

	}

	&:hover::before {
		background-color: rgba(#FF9801, 0.25);
	}

}

.bold {

	font-weight: $medium;

	background-image: linear-gradient(90deg, #FF9900, #FF565B, #FF9900);
	background-size: 200% 100%;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	transition: background-position 0.5s ease;

}

.list {

	max-width: 600px;
	margin-left: -40px;

	font-size: 18px;
	color: $color-grayscale-80;
	line-height: 1.75;

	@media (max-width: 700px) {
		margin-left: -20px;
	}

	@media (prefers-color-scheme: dark) {
		color: #dfdfdf;
	}

}

.item {

	margin-bottom: 10px;

	&:last-of-type {
		margin-bottom: 0;
	}

}
</style>
