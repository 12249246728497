<template>
	<div id="app">
		<SiteHeader/>
		<Sections/>
	</div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import Sections from './components/Sections.vue'

export default {
	name: 'app',
	components: {
		SiteHeader,
		Sections
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/fonts.scss';

body {

	margin: 100px 0;

	@media (max-width: 1050px) {
		margin: 8% 0;
	}

	@media (prefers-color-scheme: dark) {
		background: $color-grayscale-100;
	}

}

a {

	text-decoration: none;

}

svg {

	outline: none;

}

.col {

	padding: 0 8%;

}
</style>
