<template>
	<header class="col header">
		<h1 class="greeting">Hi there, I'm <span class="image-fill">Luke.</span></h1>
		<p class="description">I'm a full-time product maker from Poland.</p>
		<ul class="social">
			<li class="item"><a class="link" target="_blank" href="https://twitter.com/lukaszmtw" rel="noopener">Twitter</a></li>
			<li class="item"><a class="link" target="_blank" href="https://instagram.com/tookbyluke" rel="noopener">Instagram</a></li>
			<li class="item"><a class="link" target="_blank" href="https://t.me/lukaszmtw" rel="noopener">Telegram</a></li>
			<li class="item"><a class="link" target="_blank" href="https://producthunt.com/@lukaszmtw" rel="noopener">Product Hunt</a></li>
			<li class="item"><a class="link" target="_blank" href="mailto:luke@mtw.team" rel="noopener">E-mail</a></li>
		</ul>
	</header>
</template>

<script>
export default {
	name: 'SiteHeader'
}
</script>

<style lang="scss" scoped>
.greeting {

	margin: 0;

	font-size: 100px;
	font-weight: $bold;
	color: $color-grayscale-100;

	@media (prefers-color-scheme: dark) {
		color: #ffffff;
	}

}

.image-fill {

	background-image: url('../assets/img/image-fill.jpg'), linear-gradient(90deg,#e67317,#f0a707,#7409a5);
	background-position-x: center;
	background-position-y: center;
	background-size: 100%;
	background-position: left center;
	background-clip: text;
	-webkit-text-fill-color: transparent;

}

.description {

	max-width: 550px;

	font-size: 48px;
	font-weight: $regular;
	color: $color-grayscale-100;
	line-height: 1.35;

	@media (prefers-color-scheme: dark) {
		color: #ffffff;
	}

}

.social {

	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;

}

.item {

	margin-right: 15px;
	margin-bottom: 15px;

	background-color: rgba(#FF9801, 0.15);
	border-radius: 6px;
	transition: background-color 0.15s ease;

	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		background-color: rgba(#FF9801, 0.25);
	}

}

.link {

	padding: 6px 11px;
	display: block;

	font-size: 18px;
	font-weight: $medium;

	background-image: linear-gradient(90deg, #FF9900, #FF565B, #FF9900);
	background-size: 200% 100%;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	transition: background-position 0.5s ease;

	&:hover {
		background-position: 100% 0;
	}

}
</style>
